html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}

.submission-table {
  border-collapse: collapse;
}
.submission-table th {
  font-size: 12px !important;
}

.submission-table-header-name {
  text-align: center !important;
  background-color: #efefef;
}

.mantine-Tabs-body {
  width: 100%;
}

.estimated_distribution_notice,
.ed_rgm_upload {
  display: none;
}

.fd_rgm_upload,
.final_distribution_notice,
.fd_review_verify,
.edn_external_links,
.fdn_external_links {
  display: none;
}
