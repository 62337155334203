section {
   padding: 2rem;
   display: flex;
 
   justify-content: center;
}


section:nth-child(odd) {
   background-color:#f1f1f1 ;
}